import React, { useState } from "react";
import TwitterIcon from "../assets/Twitter-Icon.svg";
import InstalGramIcon from "../assets/Instalgram-Icon.svg";
import LinkdinIcon from "../assets/Linkdin-icon.svg";
import HeaderLogo from "../assets/footerLogo.svg";
import FaceBookIcons from "../assets/Social_icon.svg";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import { useNavigate } from "react-router-dom";
import {
  trackFooterCoverLetterBuilderClicked,
  trackFooterJobTrackerClicked,
  trackFooterPricingClicked,
  trackFooterResumeBuilderClicked,
  trackFooterResumeReviewClicked,
} from "../utils/analytics";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleEmailSubmit } from "../utils/EmailHandlerFunction";

const Footer = () => {
  const [emailValue, setEmailValue] = useState("");
  const { navigateTo } = useWebAppNavigation();
  const navigate = useNavigate();

  //handle emailm submit
  const onSubmit = (e) => {
    handleEmailSubmit(
      {
        emailValue,
        setEmailValue,
        toast,
      },
      e
    );
  };

  return (
    <section className=" w-full bg-[#0F041F]  md:py-[64px] py-[56px] md:px-[80px] px-[1rem]">
      <ToastContainer />
      <main>
        <div className="md:flex items-start md:space-x-10 md:space-y-0 space-y-[2rem] md:justify-between justify-start">
          <div className="flex items-center space-x-[8px]">
            <img className="w-8 h-8" src={HeaderLogo} alt="headerLogo-error" />
            <h1 className="text-[#D6DDEB] text-[1rem] leading-[24px] tracking-[0.16px] font-[Satoshi-Bold]">
              SwiftCareer
            </h1>
          </div>

          <div className="space-y-[8px] flex flex-col items-start">
            <h3 className="text-[#EFE6FD] pb-[7px] text-[1rem] leading-[24px] tracking-[0.16px] font-[Satoshi-Regular]">
              Job seekers
            </h3>
            <button
              onClick={() => {
                trackFooterResumeBuilderClicked();
                navigateTo("resume_builder");
              }}
              className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]"
            >
              Create a Resume
            </button>
            <button
              onClick={() => {
                trackFooterCoverLetterBuilderClicked();
                navigateTo("cover_letter_builder");
              }}
              className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]"
            >
              Create a cover letter
            </button>
            {/* TODO: POST MVP UPDATES */}
            <button
              onClick={() => {
                trackFooterResumeReviewClicked();
                navigateTo("resume_review");
              }}
              className="font-[Satoshi-Medium] text-start text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]"
            >
              Resume Reviews and Re-write
            </button>
            <button
              onClick={() => {
                trackFooterJobTrackerClicked();
                navigateTo("job_tracker");
              }}
              className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]"
            >
              Job Application Tracker
            </button>
            <p className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]">
              {/* Interview preparation <span className="text-[#9357EA] text-sm md:hidden xl:block leading-4 p-1 bg-[#FCFBFD] rounded-[100px] font-[Satoshi-Bold]">Coming soon</span> */}
            </p>
            {/*<p className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]">*/}
            {/*  Find jobs*/}
            {/*</p>*/}
          </div>

          <div className="space-y-[8px] flex flex-col items-start">
            <h3 className="text-[#EFE6FD] pb-[7px] text-[1rem] leading-[24px] tracking-[0.16px] font-[Satoshi-Regular]">
              Our company
            </h3>
            <button
              onClick={() => {
                trackFooterPricingClicked();
                navigate("/pricing");
              }}
              className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]"
            >
              Pricing
            </button>
            <p className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]">
              Product updates
            </p>
          </div>

          <div className="space-y-[8px] flex flex-col items-start">
            <h3 className="text-[#EFE6FD] pb-[7px] text-[1rem] leading-[24px] tracking-[0.16px] font-[Satoshi-Regular]">
              Support
            </h3>
            <p className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]">
              FAQ
            </p>
            <button
              onClick={() => {
                navigateTo("contact_us");
              }}
              className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]"
            >
              Help
            </button>
            <p className="font-[Satoshi-Medium] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]">
              Privacy Policy
            </p>
          </div>

          <div className="space-y-[8px]">
            <p className="font-[Satoshi-bold] pb-[7px] text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px]">
              Get job notifications
            </p>
            <p className="text-[#D6DDEB] text-[1rem] leading-[24px] tracking-[0.16px] font-[Satoshi-Regular]">
              The latest job news, articles, sent to your inbox weekly.
            </p>
            <form onSubmit={onSubmit} className="pt-[44px]">
              <div className="md:flex md:space-y-0 space-y-[1rem] md:space-x-[1rem]">
                <input
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  placeholder="Email Address"
                  required
                  className="md:w-[223px] w-full h-[44px] border py-[12px] px-[16px] rounded-[8px]"
                  type="email"
                />
                <input
                  className="px-[24px] cursor-pointer rounded-[8px] py-[12px] text-[#fff] text-[1rem] bg-[#9357EA] font-[Satoshi-Bold] md:w-[122px] w-full h-[44px] leading-[20px]"
                  value="Subscribe"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="mt-[56px] pt-[56px] border-[#fff] border-opacity-[0.1] border-t-[2px] md:space-y-0 space-y-[1rem] md:flex items-center justify-between">
          <p className="text-[#fff] text-[1rem] leading-[24px] tracking-[0.16px] font-[Satoshi-Regular]">
            &copy; 2024 SwiftCareer All rights reserved.
          </p>
          <div className="flex space-x-[1rem]">
            <a href="https://x.com/swiftcareer_" target="_blank">
              <img src={TwitterIcon} alt="teitterIcon" />
            </a>
            <a
              href="https://www.facebook.com/share/EmrJwte3ipT6JJ5R/?mibextid=LQQJ4d"
              target="_blank"
            >
              <img src={FaceBookIcons} alt="facebookIcon" />
            </a>
            <a
              href="https://www.instagram.com/swiftcareer_/profilecard/?igsh=MWt1N2k4cndxanZ1eQ=="
              target="_blank"
            >
              <img src={InstalGramIcon} alt="InstalGramIcon" />
            </a>
            <a
              href="https://www.linkedin.com/company/swiftcareer"
              target="_blank"
            >
              <img src={LinkdinIcon} alt="LinkdinIcon" />
            </a>
          </div>
        </div>
      </main>
    </section>
  );
};

export default Footer;
