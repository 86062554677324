import React, { useEffect, useRef, useState } from 'react';
import HeaderLogo from '../assets/originalLogo.svg';
import Harmburger from '../assets/Harmburger.svg';
import '../index.css';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import {trackLoginClicked, trackSignUpClicked} from "../utils/analytics";

const Navbar = ({setIsSideBarOpen}) => {
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [visible, setVisible] = useState(true);
	const headerRef = useRef(null);
	const logoRef = useRef(null);
	const titleRef = useRef(null);
	const navItemsRef = useRef([]);
	const loginButtonRef = useRef(null);
	const signUpButtonRef = useRef(null);
	const harmburgerRef = useRef(null);
	const { navigateTo } = useWebAppNavigation()

	const animateNavbar = () => {
		const tl = gsap.timeline({ defaults: { ease: "power3.out" } });

		tl.fromTo(logoRef.current, { x: -80, opacity: 0 }, { x: 0, opacity: 1, duration: 0.5 })
			.fromTo(titleRef.current, { x: -30, opacity: 0 }, { x: 0, opacity: 1, duration: 0.5 }, "-=0.3")
			.fromTo(navItemsRef.current, { y: -20, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5, stagger: 0.1 }, "-=0.3")
			.fromTo(loginButtonRef.current, { scale: 0.5, opacity: 0 }, { scale: 1, opacity: 1, duration: 0.5 }, "-=0.3")
			.fromTo(signUpButtonRef.current, { scale: 0.5, opacity: 0 }, { scale: 1, opacity: 1, duration: 0.5 }, "-=0.3")
			.fromTo(harmburgerRef.current, { x: 30, opacity: 0 }, { x: 0, opacity: 1, duration: 0.3 }, "-=1.2");
	};

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			const isVisible = prevScrollPos > currentScrollPos || currentScrollPos < 200;

			if (isVisible && !visible) {
				setVisible(true);
				// Delay the animation slightly to allow the navbar to become visible
				setTimeout(animateNavbar, 50);
			} else if (!isVisible && visible) {
				setVisible(false);
			}

			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [prevScrollPos, visible]);

	// Initial animation
	useGSAP(() => {
		animateNavbar();
	}, []);

	const navigateToLogin = () => {
		trackLoginClicked()
		navigateTo('login')
	}

	const navigateToSignUp = () => {
		trackSignUpClicked()
		navigateTo('signup')
	}


	return (
		<header 
			ref={headerRef}
			className={`border-b bg-[#fff] z-50 top-0 fixed border-[#DEDFE0] h-[84px] flex md:px-[80px] px-[16px] w-full transition-all duration-500 ease-in-out ${
				visible ? 'translate-y-0' : '-translate-y-full'
			}`}
		>
			<nav className=' w-full flex justify-between items-center'>
				<div className='flex items-center space-x-2'>
					<img ref={logoRef} className='w-[32px] bg-white h-[32px] object-cover' src={HeaderLogo} alt="headerLogo-error" />
					<h3 ref={titleRef} className='text-[#35254B] text-[18px] font-[Satoshi-Black] leading-[24px] capitalize'>Swift<span className='text-[#9357EA]'>Career</span></h3>
				</div>

				<ul className='md:flex hidden space-x-8 items-center'>
					{['Blog', 'Resume Builder', 'Cover letter Builder'].map((text, index) => (
						<li key={index} ref={el => navItemsRef.current[index] = el}>
							{index === 0 ? (
								<a href="https://medium.com/@swiftcareer_" target='_blank' className='text-4 text-[#515B6F] cursor-pointer font-[Satoshi-Bold] leading-5'>{text}</a>
							) : index === 1 ? (
								<a href="#AIResumeBuilder" className='text-4 text-[#515B6F] cursor-pointer font-[Satoshi-Bold] leading-5'>{text}</a>
							) : index === 2 ? (
								<a href="#AICoverLetterBuilder" className='text-4 text-[#515B6F] cursor-pointer font-[Satoshi-Bold] leading-5'>{text}</a>
							) : (
								<span className='text-4 text-[#515B6F] cursor-pointer font-[Satoshi-Bold] leading-5'>{text}</span>
							)}
						</li>
					))}
				</ul>

				<div className='md:flex hidden items-center space-x-8'>
					<button ref={loginButtonRef} className='text-[16px] font-[Satoshi-Bold] text-[#9357EA] px-[24px] py-[12px] leading-[20px]' onClick={navigateToLogin}>Login</button>
					<button ref={signUpButtonRef} className='text-[16px] font-[Satoshi-Bold] text-[#fff] rounded-[4px] bg-[#9357EA] px-[24px] py-[12px] leading-[20px]' onClick={navigateToSignUp}>Sign Up</button>
				</div>

				<div onClick={() => setIsSideBarOpen(true)} ref={harmburgerRef} className='md:hidden flex px-2 border-[1px] rounded-full border-[#DEDFE0] py-2'>
					<img  className='w-[24px] h-[24px] cursor-pointer' src={Harmburger} alt="harmburger-img" />
				</div>
			</nav>
		</header>
	);
}

export default Navbar;
