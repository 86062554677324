import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_ID ?? "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? "",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;