import React from "react";

import Marquee from "react-fast-marquee";

const ReviewText = ({review, quoteSvg, name, image,occupation }) => {
  return (
    <div className="p-[2rem] overflow-hidden md:w-[389px] w-[343px] h-[288px] rounded-[1rem] bg-[#FAFAFA] mx-[15px]">
      <img src={quoteSvg} alt="quoteSvg" />

      <div className="mt-[24px]">
        <h2 className="text-[18px] font-[Satoshi-Bold] leading-[24px]">
          {review}
        </h2>
        <div className="w-full mt-[1rem] flex space-x-[12px] items-center">
          <img
            className="w-[40px] h-[40px] border border-[#DEDFE0] rounded-[100px]"
            src={image}
            alt=""
          />
          <div className="">
            <h4 className="text-[12px] font-[Satoshi-Bold] text-[#1B1E21] leading-[1rem]">
              {name}
            </h4>
            <p className="text-[12px] pt-[7px] font-[Satoshi-Regular] leading-[1rem] text-[#515B6F]">
              {occupation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewText;
