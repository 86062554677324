import {formatPrice} from "../utils/format_price";
import React from "react";
import IcSubCheck from '../assets/ic_sub_check.svg'
import IcCrownGold from '../assets/ic_crown_gold.svg'

export default function SubscriptionPlanItem({className, onBuyNowClicked}){
    return (
        <div
            className={`${className} p-6 border border-grey-scale-border rounded-2xl`}>
            <div className={'flex flex-col gap-6'}>
                <div className={'flex flex-row justify-between items-center'}>
                    <div className="text-zinc-600 text-xs font-black font-satoshi uppercase leading-none tracking-wide">
                        BETA PLAN
                    </div>

                    <div
                        className={
                            'p-2 flex flex-row gap-2 bg-[#F5F1E6] rounded'
                        }>
                        <img src={IcCrownGold} alt={'Crown'}/>

                        <p
                            className={
                                'text-[#9E7C1D] font-satoshi text-xs font-bold'
                            }>
                            Limited time offer
                        </p>
                    </div>
                </div>

                <div>
					<span className="text-zinc-900 text-[28px] font-black font-satoshi leading-9">
						{formatPrice(50, 'USD')}
					</span>
                    <span className="text-zinc-400 text-base font-bold font-satoshi leading-tight">
						/Lifetime
					</span>
                </div>

                <div className={'flex flex-col gap-4'}>
                    <div className={`flex flex-row gap-3 items-center`}>
                        <img src={IcSubCheck} alt={`BETA LAUNCH PLAN`}/>

                        <div className="text-zinc-600 text-sm font-normal font-satoshi leading-normal tracking-tight">
                            AI Assistance
                        </div>
                    </div>

                    <div className={`flex flex-row gap-3 items-center`}>
                        <img src={IcSubCheck} alt={`BETA LAUNCH PLAN`}/>

                        <div className="text-zinc-600 text-sm font-normal font-satoshi leading-normal tracking-tight">
                            Create and Download as many resumes and cover letters as you want, forever
                        </div>
                    </div>

                    <div className={`flex flex-row gap-3 items-center`}>
                        <img src={IcSubCheck} alt={`BETA LAUNCH PLAN`}/>

                        <div className="text-zinc-600 text-sm font-normal font-satoshi leading-normal tracking-tight">
                            Access all the current features and feature updates
                        </div>
                    </div>

                    <div className={`flex flex-row gap-3 items-center`}>
                        <img src={IcSubCheck} alt={`BETA LAUNCH PLAN`}/>

                        <div className="text-zinc-600 text-sm font-normal font-satoshi leading-normal tracking-tight">
                            20 AI assisted cover letter writing per month
                        </div>
                    </div>

                    <div className={`flex flex-row gap-3 items-center`}>
                        <img src={IcSubCheck} alt={`BETA LAUNCH PLAN`}/>

                        <div className="text-zinc-600 text-sm font-normal font-satoshi leading-normal tracking-tight">
                            10 resume reviews per month
                        </div>
                    </div>

                    <div className={`flex flex-row gap-3 items-center`}>
                        <img src={IcSubCheck} alt={`BETA LAUNCH PLAN`}/>

                        <div className="text-zinc-600 text-sm font-normal font-satoshi leading-normal tracking-tight">
                            10 resume re-writes per month
                        </div>
                    </div>

                </div>

                <button
                    className='text-[16px] font-[Satoshi-Bold] text-[#fff] rounded-[4px] bg-[#9357EA] px-[24px] py-[12px] leading-[20px]'
                    onClick={onBuyNowClicked}>Buy Now
                </button>
            </div>
        </div>
    )
}