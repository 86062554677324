import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import HeroImg from "../assets/HeroImg.webp";
import HeroRightImg1 from "../assets/imola_template.webp";
import HeroRightImg2 from "../assets/Monza_resume_template_preview.webp";
import HeroRightImg3 from "../assets/doha_template.webp";
import Navbar from "./Navbar";
import HeroTyped from "./HeroTyped";
import { useCountUp } from "react-countup";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import { trackGetStartedNow } from "../utils/analytics";

// screens: {
//   'sm': '640px',
//   // => @media (min-width: 640px) { ... }

//   'md': '1070px',
//   // => @media (min-width: 768px) { ... }

//   'lg': '1024px',
//   // => @media (min-width: 1024px) { ... }

//   'xl': '1280px',
//   // => @media (min-width: 1280px) { ... }

//   '2xl': '1536px',
//   // => @media (min-width: 1536px) { ... }
// }

const Hero = ({ setIsSideBarOpen }) => {
  const heroSectionStyle = {
    backgroundImage: `url(${HeroImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  };

  // eslint-disable-next-line no-unused-vars
  const [_shouldCount, setShouldCount] = useState(false); // Set initial value to true

  const heroRef = useRef(null);
  const titleRef = useRef(null);
  const buttonRef = useRef(null);
  const statsRef = useRef(null);
  const titleLines = useRef([]);

  const { navigateTo } = useWebAppNavigation();

  const CountUpWrapper = ({ end, duration, decimals }) => {
    const countUpRef = useRef(null);
    const { start, reset } = useCountUp({
      ref: countUpRef,
      start: 0,
      end: end,
      duration: duration,
      decimals: decimals,
    });

    useEffect(() => {
      // console.log('CountUpWrapper mounted, starting count');
      start();
      return () => {
        // console.log('CountUpWrapper unmounted');
        reset();
      };
    }, [start, reset]);

    return <span ref={countUpRef} />;
  };

  useLayoutEffect(() => {
    ScrollTrigger.refresh();
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }
    const hero = heroRef.current;
    //const title = titleRef.current;
    const button = buttonRef.current;
    //const stats = statsRef.current;

    // gsap.fromTo(
    //   title,
    //   { opacity: 0 },
    //   { opacity: 1, duration: 0.5, scrollTrigger: { trigger: hero } }
    // );
    // gsap.fromTo(
    //   button,
    //   { opacity: 0 },
    //   { opacity: 1, duration: 1, delay: 0.6, scrollTrigger: { trigger: hero } }
    // );

    // gsap.fromTo(
    //   stats.children,
    //   { scale: 0.3, opacity: 0 },
    //   {
    //     scale: 1,
    //     opacity: 1,
    //     duration: 0.8,
    //     stagger: 0.2,
    //     scrollTrigger: { trigger: hero },
    //   }
    // );

    // Updated button animation
    const mediaQueryT = window.matchMedia("(min-width: 1024px)");

    // gsap.set(titleLines.current, { y: 50, opacity: 0 });
    // gsap.to(titleLines.current, {
    //   y: 0,
    //   opacity: 1,
    //   duration: 0.8,
    //   stagger: {
    //     each: 0.2,
    //   },
    //   ease: "power3.out",
    //   scrollTrigger: {
    //     markers: true,
    //     trigger: titleRef.current,
    //     start: "top 70%",
    //   },
    // });

    if (mediaQueryT.matches) {
      //    // Text animation
      ScrollTrigger.refresh();
      // animations for large screens
      const largeTimeLine = gsap.timeline();

      largeTimeLine.set(titleLines.current, { y: 60, opacity: 0 });
      largeTimeLine.to(titleLines.current, {
        y: 0,
        opacity: 1,
        duration: 0.6,
        delay: 1,
        stagger: {
          each: 0.2,
        },
        ease: "power3.out",
      });

      // Pop-up animation for large screens
      largeTimeLine.fromTo(
        button,
        { scale: 0.6, opacity: 0, visibility: "hidden" },
        {
          scale: 1,
          opacity: 1,
          visibility: "visible",
          duration: 0.4,
          delay: 1,
          ease: "elastic.out(1, 0.5)",
          scrollTrigger: { trigger: hero },
        },
        "-=1.3"
      );
      // image one
      largeTimeLine.fromTo(
        "#mediumImageOne",
        {
          opacity: 0,
          x: -500,
        },
        {
          opacity: 1,
          x: 0,
          duration: 0.6,
        }
        // "-=0.4"
      );

      // image two
      // largeTimeLine.fromTo(
      //   "#mediumImageTwo",
      //   {
      //     opacity: 0,
      //     y: 500,
      //   },
      //   {
      //     opacity: 1,
      //     y: 0,
      //     duration: 0.7,
      //   }, "-=0.4"
      // );

      largeTimeLine.fromTo(
        "#mediumImageThree",
        {
          opacity: 0,
          x: 500,
          force3D: true,
        },
        {
          opacity: 1,
          x: 0,
          duration: 0.6,
          // clearProps: "transform",
        }
      );
    } else {
      ScrollTrigger.refresh();
      const largeTimeLine = gsap.timeline();

      largeTimeLine.set(titleLines.current, { y: 20, opacity: 0 });
      largeTimeLine.to(titleLines.current, {
        y: 0,
        opacity: 1,
        delay: 1,
        duration: 0.5,
        stagger: {
          each: 0.2,
        },
        ease: "power3.out",
      });

      // Smooth fade-in animation for small screens
      largeTimeLine.fromTo(
        button,
        { y: 20, opacity: 0, visibility: "hidden" },
        {
          y: 0,
          opacity: 1,
          visibility: "visible",
          duration: 0.5,
          delay: 1,
          ease: "power2.out",
          // scrollTrigger: { trigger: hero },
        },
        "-=1.2"
      );
    }
    // animation for medium
    const mediaQueryMobile = window.matchMedia("(max-width: 1070px)");
    // console.log('small screen')
    if (mediaQueryMobile.matches) {
      gsap.fromTo(
        "#ImageOne",
        {
          opacity: 0,
          duration: 1,
          x: -500,
        },
        {
          scrollTrigger: {
            // markers: true,
            trigger: "#ImageOne",
            start: "top bottom-=15%",
            end: "top bottom-=135%",
            toggleActions: "play none play reverse",
          },
          opacity: 1,
          duration: 0.6,
          x: 0,
        }
      );

      gsap.fromTo(
        "#ImageTwo",
        {
          opacity: 0,
          duration: 1,
          y: 500,
        },
        {
          scrollTrigger: {
            trigger: "#ImageOne",
            start: "top bottom-=10%",
            end: "top bottom-=135%",
            toggleActions: "play none play reverse",
          },
          opacity: 1,
          duration: 0.7,
          y: 0,
        }
      );

      gsap.fromTo(
        "#ImageThree",
        {
          opacity: 0,
          duration: 1,
          x: 500,
        },
        {
          scrollTrigger: {
            trigger: "#ImageOne",
            start: "top bottom-=10%",
            end: "top bottom-=135%",
            toggleActions: "play none play reverse",
          },
          opacity: 1,
          duration: 0.6,
          x: 0,
        }
      );
    }

    // Remove the ScrollTrigger for counter reset
    // Instead, we'll use the IntersectionObserver API

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldCount(true);
        } else {
          setShouldCount(false);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }

      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  const navigateToDocuments = () => {
    trackGetStartedNow();
    navigateTo("documents");
  };

  return (
    <section style={heroSectionStyle} ref={heroRef}>
      <Navbar setIsSideBarOpen={setIsSideBarOpen} />
      <section className=" w-full md:px-[5rem] px-[16px] justify-center pt-[3.5rem] pb-[5.8rem] mt-[5rem] h-full">
        <main className="md:flex justify-between">
          <div className=" md:w-[48%] w-full">
            <h1
              ref={titleRef}
              className="md:text-[3.5rem] text-[#1B1E21] text-[2.5rem] md:leading-[4.5rem] leading-[3.25rem] font-[Satoshi-Black] "
            >
              <div ref={(el) => (titleLines.current[0] = el)}>
                Land Your Dream Job
              </div>
              <div ref={(el) => (titleLines.current[1] = el)}>
                With our AI-Powered
              </div>
              <div
                ref={(el) => (titleLines.current[2] = el)}
                className="text-[#9357EA]"
              >
                <HeroTyped />
              </div>
              <div ref={(el) => (titleLines.current[3] = el)}>Builder</div>
            </h1>
            <p
              ref={(el) => (titleLines.current[4] = el)}
              className="md:text-base font-[Satoshi-Regular] font-normal text-[#515B6F] text-[1rem] leading-[1.5rem] tracking-[0.16px] mt-4"
            >
              With us you're one step closer to your dream job: Craft resumes
              and cover letters that get noticed, effortlessly track
              applications, and ace your interviews.
            </p>

            <button
              ref={buttonRef}
              className="text-[1rem] md:w-[190px] w-full h-[48px] mt-10 rounded-[4px] bg-[#9357EA] px-[1.6rem] py-[0.8rem] leading-[1.4rem] text-[#fff] font-[Satoshi-Bold]"
              onClick={navigateToDocuments}
            >
              Get Started Now
            </button>

            <div
              ref={statsRef}
              className="w-full mt-10 md:space-x-[24px] md:space-y-0 lg:mt-10 space-y-[24px] md:flex md:justify-start text-center justify-center"
            >
              <div className="pr-[1.5rem] md:text-start md:border-r border-[#DEDFE0]">
                <p className="text-[1rem] md:hidden leading-[1.5rem] font-[Satoshi-Regular] text-[#515B6F] tracking-[0.16px]">
                  Resume created
                </p>
                <h1 className="text-[1.5rem] md:text-start leading-[2rem] py-1 text-[#1B1E21] font-[Satoshi-Black]">
                  <CountUpWrapper end={250} duration={10} />+
                </h1>
                <p className="text-4 md:block hidden leading-[1.5rem] pt-[1rem] font-[Satoshi-Regular] text-[#515B6F] tracking-[0.16px]">
                  Resume & cover letter created
                </p>
              </div>
              <div className="pr-[1.5rem] md:text-start md:border-r border-[#DEDFE0]">
                <p className="text-[1rem] md:py-1 md:hidden block leading-[1.5rem] font-[Satoshi-Regular] text-[#515B6F] tracking-[0.16px]">
                  Resume review & re-writing
                </p>
                <h1 className="text-[1.5rem] md:text-start leading-[2rem] text-[#1B1E21] py-1 font-[Satoshi-Black]">
                  <CountUpWrapper end={200} duration={11} />+
                </h1>

                <p className="text-[1rem] md:block hidden leading-[1.5rem] pt-[1rem] font-[Satoshi-Regular] text-[#515B6F] tracking-[0.16px]">
                  Resume review & re-writing
                </p>
              </div>
              <div className="pr-[1.5rem] md:text-start">
                <p className="text-[1rem] md:hidden block leading-[1.5rem] font-[Satoshi-Regular] text-[#515B6F] tracking-[0.16px]">
                  Ratings
                </p>
                <h1 className="text-[1.5rem] py-1 leading-[2rem] text-[#1B1E21] font-[Satoshi-Black]">
                  <CountUpWrapper end={4.9} duration={12} decimals={1} />
                </h1>
                <p className="text-[1rem] md:block hidden leading-[1.5rem] pt-[1rem] font-[Satoshi-Regular] text-[#515B6F] tracking-[0.16px]">
                  Ratings
                </p>
              </div>
            </div>
          </div>

          {/* Right */}
          <div className=" md:w-[50%] h-[550px] md:block hidden w-full relative overflow-hidden rounded-[1rem] bg-[#F0F0F1]">
            <div className="md:flex justify-center hidden ">
              <img
                id="mediumImageOne"
                className="w-[331px] shadow-2xl rounded-[14.335px] h-[468px] -ml-[260px] -rotate-[28deg] top-24 absolute"
                src={HeroRightImg1}
                alt="HeroRightImg"
              />
              <img
                id="mediumImageTwo"
                className=" w-[380px] shadow-2xl rounded-[14.335px] h-[468px] ml-[20px] top-[17px]  absolute"
                src={HeroRightImg2}
                alt="HeroRightImg"
              />
              <img
                id="mediumImageThree"
                className="w-[331px] shadow-2xl rounded-[14.335px] h-[468px] rotate-[20deg] top-28 ml-[300px] absolute"
                src={HeroRightImg3}
                alt="HeroRightImg"
              />
            </div>
          </div>

          {/* mobile screen */}
          <div className=" w-full md:hidden mt-10 sm:pt-[550px] pt-[300px] relative overflow-hidden rounded-[1rem] bg-[#F0F0F1]">
            <div className="flex justify-center ">
              <img
                id="ImageOne"
                className=" w-[200px]  rounded-[14.335px] sm:w-[330px] sm:h-[468px] sm:-ml-[280px] -ml-[150px] sm:-rotate-[28deg] top-20 -rotate-[30deg] sm:top-20 absolute"
                src={HeroRightImg1}
                alt="HeroRightImg"
              />
              <img
                id="ImageTwo"
                className=" w-[200px] rounded-[14.335px] sm:w-[380px] sm:h-[468px] sm:ml-[20px] top-[17px] absolute"
                src={HeroRightImg2}
                alt="HeroRightImg"
              />
              <img
                id="ImageThree"
                className=" w-[200px] rounded-[14.335px] sm:w-[330px] sm:h-[468px] sm:ml-[350px] ml-[170px] sm:rotate-[20deg] top-20 rotate-[25deg] sm:top-28 absolute"
                src={HeroRightImg3}
                alt="HeroRightImg"
              />
            </div>
          </div>
        </main>
      </section>
    </section>
  );
};

export default Hero;
