import React, { useState, useRef, useEffect } from "react";
import PlusSvg from "../assets/plus-circle.svg";
import Minus from "../assets/minus-circle.svg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { trackQandAItemClicked } from "../utils/analytics";

const Frequently = () => {
  const [selected, setSelected] = useState(null);
  const contentRefs = useRef([]);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from("#fREQUENTLY", {
      scrollTrigger: {
        // markers: true,
        trigger: "#fREQUENTLY",
        scrub: 1,
        start: "top center+=30%",
        end: "bottom bottom",
      },
      y: 100,
      opacity: 0,
      duration: 0.3,
    });
  }, []);

  const frequentAskArray = [
    {
      id: "1",
      question: `What is SwiftCareer and why should I use it?`,
      answer: `SwiftCareer is a comprehensive toolkit designed to empower professionals in their career journey. It offers a range of tools to help you secure your dream job and advance your career, including:`,
      list: [
        `An AI-powered resume and cover letter builder`,
        `Professional resume review and rewriting services`,
        `A comprehensive interview preparation kit`,
        `By utilizing SwiftCareer, you can optimize your job search process and increase your chances of landing the position you desire.`,
      ],
    },
    {
      id: "2",
      question: `Is there a free trial available?`,
      answer: `   Yes, SwiftCareer offers a free tier that provides full access to select features and limited access to others. This allows you to experience the platform's capabilities before committing to a paid plan.`,
    },
    {
      id: "3",
      question: `How does the resume review and rewrite feature work?`,
      answer: `The resume review and rewrite process is straightforward:  `,
      orderedList: [
        "Upload your current resume to our system",
        "Our AI-powered algorithm analyzes and scores your resume based on industry-standard criteria",
        "You receive detailed feedback and suggestions for improvement",
        "If desired, our system can automatically rewrite your resume to enhance its impact and effectiveness",
      ],
    },
    {
      id: "4",
      question: `How can I obtain lifetime access to SwiftCareer?`,
      answer: `   During our BETA launch period, we're offering an exclusive lifetime access plan. This package includes: `,
      list: [
        "10 resume reviews and rewrites per month",
        "20 AI-generated cover letters per month",
        "Unlimited resume and cover letter downloads",
        "These benefits are available every month for the entire lifetime of the product, ensuring long-term value for your career development.",
      ],
    },
    {
      id: "5",
      question: `Can I access SwiftCareer on mobile devices?`,
      answer: `   Absolutely. SwiftCareer is fully optimized as a web application, accessible on mobile devices through any of our supported browsers. For the best experience, we recommend using Chrome, Safari, Edge, or Firefox.`,
    },
  ];

  const clickQuestion = (index) => {
    setSelected(index === selected ? null : index);
  };

  useEffect(() => {
    contentRefs.current.forEach((ref, index) => {
      if (ref) {
        if (index === selected) {
          trackQandAItemClicked(frequentAskArray[selected]);
          gsap.to(ref, {
            height: ref.scrollHeight,
            duration: 0.3,
            ease: "power2.out",
          });
        } else {
          gsap.to(ref, { height: 0, duration: 0.3, ease: "power2.out" });
        }
      }
    });
  }, [selected]);

  return (
    <section className="w-full flex justify-center items-center md:py-[64px] py-[56px] md:px-[80px] px-[16px]">
      <div id="FAQ" className="md:px-[32px] w-full">
        <div className="md:w-[448px] md:pb-[64px] pb-[40px] m-auto md:text-center text-start">
          <div className="overflow-hidden">
            <h1
              id="fREQUENTLY"
              className="md:text-[40px] md:mx-0 mx-4 font-[Satoshi-Black] text-[#1B1E21] text-[34px] font-black md:leading-[52px] leading-[44px]"
            >
              Frequently Asked Questions
            </h1>
          </div>
        </div>

        {frequentAskArray.map((item, index) => (
          <div
            key={item.id}
            onClick={() => clickQuestion(index)}
            className={`mt-4 overflow-hidden cursor-pointer transition-all duration-300 ease-in-out ${
              selected === index
                ? "rounded-[16px] bg-[#F9FAFB] md:p-8 p-4"
                : " md:p-8 p-4 rounded-[16px]"
            }`}
          >
            <div className="md:px-[10px]">
              <div className="flex items-center justify-between space-x-[24px] cursor-pointer">
                <h3 className="md:text-[16px] font-[Satoshi-Bold] text-[16px] md:leading-[24px] leading-[20px] md:font-bold text-[#1B1E21] font-black">
                  {item.question}
                </h3>
                <div className="relative w-6 h-6 flex-shrink-0">
                  <img
                    src={PlusSvg}
                    alt="Plus"
                    className={`w-full h-full absolute top-0 left-0 transition-all duration-300 ${
                      selected === index
                        ? "opacity-0 rotate-90"
                        : "opacity-100 rotate-0"
                    }`}
                  />
                  <img
                    src={Minus}
                    alt="Minus"
                    className={`w-full h-full absolute top-0 left-0 transition-all duration-300 ${
                      selected === index
                        ? "opacity-100 rotate-0"
                        : "opacity-0 rotate-90"
                    }`}
                  />
                </div>
              </div>
              <div
                ref={(el) => (contentRefs.current[index] = el)}
                className="overflow-hidden"
                style={{ height: 0 }}
              >
                <p className="md:text-[16px] font-[Satoshi-Regular] pt-[8px] text-[14px] text-[#4B525A] leading-[24px] font-normal md:tracking-[0.16px] tracking-[0.14px]">
                  {item.answer}
                </p>

                <ul className="list-disc pl-5">
                  {item.list &&
                    item.list.length > 0 &&
                    item.list.slice(0, -1).map(
                      (
                        listItem,
                        listIndex // Render all but the last item
                      ) => (
                        <li
                          key={listIndex}
                          className="md:text-[16px] font-[Satoshi-Regular] pt-[8px] text-[14px] text-[#4B525A] leading-[24px] font-normal md:tracking-[0.16px] tracking-[0.14px]"
                        >
                          {listItem}
                        </li>
                      )
                    )}
                </ul>
                {item.list &&
                  item.list.length > 0 && ( // Render the last item as normal text
                    <p className="md:text-[16px] font-[Satoshi-Regular] pt-[8px] text-[14px] text-[#4B525A] leading-[24px] font-normal md:tracking-[0.16px] tracking-[0.14px]">
                      {item.list[item.list.length - 1]}
                    </p>
                  )}

                <ol className="list-decimal pl-5">
                  {" "}
                  {/* Use ordered list */}
                  {item.orderedList &&
                    item.orderedList.length > 0 &&
                    item.orderedList.map((listItem, listIndex) => (
                      <li
                        key={listIndex}
                        className="md:text-[16px] font-[Satoshi-Regular] pt-[8px] text-[14px] text-[#4B525A] leading-[24px] font-normal md:tracking-[0.16px] tracking-[0.14px]"
                      >
                        {listItem}
                      </li>
                    ))}
                </ol>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Frequently;
