import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import AiImage from "../assets/groupImages/imageOne.svg";
import arrowLeftIcons from "../assets/groupImages/arrow-narrow-left-one.svg";
import ScrollTrigger from "gsap/ScrollTrigger";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import {trackResumeReviewClicked} from "../utils/analytics";

const AiResumeReview = () => {
  const sectionRef = useRef(null);
  const { navigateTo } = useWebAppNavigation()

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(sectionRef.current, {
        opacity: 0,
        y: 50,
        duration: 0.5,
        ease: "power3.out",
        scrollTrigger: {
          // markers: true,
          trigger: sectionRef.current,
          start: "top center+=10%",
          end: "top center",
          toggleActions: "play none none reverse",
        },
      });

      gsap.from("#leftItems > *", {
        x: -100,
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          // markers: true,
          trigger: "#leftItems",
          start: "top center+=30%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });

      gsap.from("#AiImg", {
        x: 100,
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          // markers: true,
          trigger: "#AiImg",
          start: "top center+=15%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });
    }, sectionRef); // Scope to sectionRef

    return () => ctx.revert(); // Cleanup

    //  return () => ScrollTrigger.getAll().forEach((t) => t.kill());
  }, []);

  const navigateToResumeReview = () => {
    trackResumeReviewClicked()
    navigateTo('resume_review')
  }

  return (
    <section
      ref={sectionRef}
      id="aiResumeSection"
      className="w-full rounded-2xl bg-[#F6F5F1] md:py-10 py-10 md:mt-10 mt-6 md:px-14 px-4"
    >
      <main className="md:flex overflow-hidden w-full md:justify-between justify-center items-center">
        <div id="leftItems" className="">
          <h1
            id="leftItems"
            className="md:text-3xl md:mb-2 text-2xl text-[#1B1E21] font-[Satoshi-Black] md:leading-10 leading-8 "
          >
            Instant Resume Review & Re-write
          </h1>
          {/* <p
            id="leftItems"
            className="p-1 md:hidden block sm:block bg-[#FBEED4] my-1 text-xs font-[Satoshi-Medium] leading-4 w-24 text-center rounded-full h-6 "
          >
            Coming soon
          </p> */}
          <p
            id="leftItems"
            className="md:text-4 text-sm text-[#515B6F] font-[Satoshi-Regular] tracking-wider leading-6"
          >
            Get instant feedback on your resume and discover exactly how to improve it. With SwiftCareer’s resume review tool, you’ll receive a detailed analysis highlighting strengths, weaknesses, and actionable tips to optimize your resume for any job. Boost your chances of standing out with a resume that meets today's hiring standards!
          </p>
          <button className="mt-4 space-x-2 flex items-center" onClick={navigateToResumeReview}>
            <p className="text-4 text-[#5F4D05] font-[Satoshi-Black] leading-5">
              Upload resume
            </p>
            <img src={arrowLeftIcons} alt="arrowLeftIcons" />
          </button>
        </div>

        <img
          id="AiImg"
          className="md:w-1/2 sm:w-[80%] w-full md:h-auto h-[300px] mx-auto md:mt-0 mt-8"
          src={AiImage}
          alt="AiImage"
        />
      </main>
    </section>
  );
};

export default AiResumeReview;
