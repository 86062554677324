import React, { useState } from "react";

const SingResume = ({ item, templateName, image, key }) => {
  const [showOverLay, setShowOverLay] = useState(false);


  return (
    <section className="">
      <div
        className=" py-[32px]"
      >
         <div
         onMouseOver={() => setShowOverLay(true)}
         onMouseOut={() => setShowOverLay(false)}
         className="relative">
          
      {/* Image */}
      <div
       className="bg-[#FAFAFA] rounded-[8px] border p-[20px]">
      <img
        className=" w-full  rounded-[8px]"
        src={image}
        alt="image"
      />
      </div>

      {/* Overlay */}
      {showOverLay && (
        <div className="absolute flex items-center m-auto top-0 left-0 w-full h-full bg-black opacity-50 rounded-[8px]"></div>
      )}

      {/* Button on top of overlay */}
      <div className="flex justify-center items-end">
      {showOverLay && (
        <button className="absolute text-[#9357EA] md:top-[44%] top-[45%] font-[Satoshi-Black] px-[27px] my-[14px] z-2 mx-[1rem] rounded-[4px] bg-[#fff] py-[12px]">
          Choose this template
        </button>)}
      </div>
      </div>


        <div className="w-full flex justify-center">
        <button className="px-[27px] my-[14px] md:hidden rounded-[4px] bg-[#9357EA] py-[12px]">
          Choose this template
        </button>
        </div>
        <h5 className="text-[1rem] pt-[1rem] font-[Satoshi-Black] leading-[20px]">
          {templateName}
        </h5>
      </div>
    </section>
  );
};

export default SingResume;
