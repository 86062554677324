import analytics from "./firebase_analytics_setup";

import {logEvent} from "firebase/analytics";


const trackLoginClicked = () => {
    logEvent(analytics, 'login');
}

const trackSignUpClicked = () => {
    logEvent(analytics, 'sign_up');
}

const trackGetStartedNow = () => {
    logEvent(analytics, 'get_started_now');
}

// -------------------------- Cards sections analytics ----------------------------
const trackResumeReviewClicked = () => {
    logEvent(analytics, 'resume_review_card');
}

const trackJobTrackerClicked = () => {
    logEvent(analytics, 'job_tracker_card');
}

const trackResumeBuilderClicked = () => {
    logEvent(analytics, 'resume_builder_card');
}

const trackCoverLetterBuilderClicked = () => {
    logEvent(analytics, 'cover_letter_builder_card');
}

//--------------------------- Resume Builder Details section analytics --------------------------
const trackResumeBuilderBottomDetailsClicked = () => {
    logEvent(analytics, 'resume_builder_bottom_details');
}


const trackCoverLetterBuilderBottomDetailsClicked = () => {
    logEvent(analytics, 'cover_letter_builder_bottom_details');
}

const trackQandAItemClicked = (item) => {
    logEvent(analytics, 'q_and_a_item_clicked', {
        question: item
    });
}

//----------------------------- Footer section analytics ----------------------------------------
const trackFooterResumeBuilderClicked = () => {
    logEvent(analytics, 'footer_resume_builder');
}

const trackFooterCoverLetterBuilderClicked = () => {
    logEvent(analytics, 'footer_cover_letter_builder');
}

const trackFooterResumeReviewClicked = () => {
    logEvent(analytics, 'footer_resume_review');
}

const trackFooterJobTrackerClicked = () => {
    logEvent(analytics, 'footer_job_tracker');
}

const trackFooterPricingClicked = () => {
    logEvent(analytics, 'footer_pricing');
}

export {
    trackLoginClicked,
    trackSignUpClicked,
    trackGetStartedNow,
    trackResumeReviewClicked,
    trackJobTrackerClicked,
    trackResumeBuilderClicked,
    trackFooterJobTrackerClicked,
    trackFooterResumeReviewClicked,
    trackFooterCoverLetterBuilderClicked,
    trackResumeBuilderBottomDetailsClicked,
    trackFooterPricingClicked,
    trackCoverLetterBuilderBottomDetailsClicked,
    trackCoverLetterBuilderClicked,
    trackQandAItemClicked,
    trackFooterResumeBuilderClicked
};