import React from "react";
import CircleCheck from "../assets/circle-check.svg";
import CoverLetterTemplates from "../assets/Resume design (CV)nw.webp";
import ImageOne from "../assets/singapore_template.webp";
import ImageTwo from "../assets/silverstone_template.webp";
import ImageThree from "../assets/suzuka_template.webp";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { trackCoverLetterBuilderBottomDetailsClicked } from "../utils/analytics";

const StandOutFromCrowd = () => {
  const { navigateTo } = useWebAppNavigation();

  useGSAP(() => {
    const mm = gsap.matchMedia();

    mm.add("(max-width: 1024px)", () => {
      // console.log('small')
      ScrollTrigger.refresh();
      const standSmallTl = gsap.timeline({
        scrollTrigger: {
          // markers: true,
          trigger: ["#standH1", "#standP"],
          start: "top center+=25%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });
      standSmallTl
        .from(["#standH1", "#standP"], {
          y: 100,
          opacity: 0,
          duration: 0.5,
        })
        .from(["#standButtons"], {
          y: 100,
          opacity: 0,
          duration: 0.8,
          ease: "power2.out",
        });
      // image animation
      gsap.from("#standImage", {
        scrollTrigger: {
          // markers: true,
          trigger: "#standImage",
          start: "top center+=20%",
          end: "top bottom",
          toggleActions: "play none none reverse",
        },
        x: -100,
        rotate: -20,
        opacity: 0,
        duration: 1,
        // stagger: 0.2,
        ease: "power2.out",
        force3D: true,
      });
    });

    mm.add("(min-width: 1024px)", () => {
      // console.log("large");
      ScrollTrigger.refresh();
      const standLarge = gsap.timeline({
        scrollTrigger: {
          // markers: true,
          trigger: "#standSec",
          start: "top center+=10%",
          end: "top bottom",
          toggleActions: "play none none reverse",
        },
      });

      standLarge
        .from(["#standH1", "#standP"], {
          y: 100,
          opacity: 0,
          duration: 0.6,
          // stagger: 0.3
          ease: "power3.out",
          force3D: true,
        })
        .from(["#standButtons"], {
          scale: 0.1,
          opacity: 0,
          duration: 0.2,
          stagger: { each: 0.3 },
          ease: "power2.out",
        })

        // image animation
        .from("#standImage", {
          x: -100,
          rotate: -20,
          opacity: 0,
          duration: 1,
          // stagger: 0.2,
          ease: "power2.out",
          force3D: true,
        });
    });
  }, []);

  const navigateToCoverLetterBuilder = () => {
    trackCoverLetterBuilderBottomDetailsClicked();
    navigateTo("cover_letter_builder");
  };

  return (
    <section
      id="standSec"
      className="bg-[#0F041F] text-white md:px-[65px] text-[bg-[#1A1A1A]] "
    >
      <main className="container md:flex md:items-center justify-between">
        <div className="md:w-[45%] w-full">
          <h1
            id="standH1"
            className="text-2xl mb-4 font-[Satoshi-Black] leading-9"
          >
            Stand Out from the Crowd with AI-Enhanced Cover Letters
          </h1>
          <p
            id="standP"
            className=" md:text-base text-[#DECBF8] mb-4 text-sm leading-6 font-[Satoshi-Regular] tracking-wide"
          >
            Distinguish yourself with expertly crafted cover letters powered by
            AI, designed to grab employers' attention.
          </p>

          <div id="standP" className=" overflow-hidden">
            {[
              "Approach job applications with confidence, knowing your cover letter shines.",
              "Quickly generate professional cover letters in minutes.",
              "Captivate hiring managers' attention with persuasive cover letters.",
              "Professionally designed templates ensure a polished presentation.",
            ].map((text, index) => (
              <div key={index} className="flex items-start mb-4">
                <img src={CircleCheck} alt="Check" className="mr-2 w-6 h-6" />
                <p className=" md:text-base text-[#DECBF8] text-sm leading-4 font-[Satoshi-Regular] tracking-wide">
                  {text}
                </p>
              </div>
            ))}
          </div>

          <div className="mt-6 md:flex grid md:grid-cols-2 md:space-x-4">
            <button
              onClick={navigateToCoverLetterBuilder}
              id="standButtons"
              className="bg-[#8B5CF6] font-[Satoshi-Regular] text-white px-4 py-2 rounded text-base"
            >
              Create my cover letter
            </button>{" "}
            {/* Changed bg-purple-500 to bg-[#8B5CF6] */}
            {/* TODO: IMPLEMENT THIS POST MVP*/}
            {/*<button id='standButtons' className=" border md:mt-0 mt-5 text-[#9357EA] border-[#473C57] rounded font-[Satoshi-Regular] px-4 py-2 text-base">Explore templates</button>*/}
          </div>
        </div>

        <main className="bg-[#F0F0F1] md:mt-0 mt-8 rounded-2xl relative overflow-hidden md:w-[500px] md:h-[400px] w-full h-[80%] md:aspect-auto aspect-[5/4]">
          <div className="bg-black w-full h-full z-40 bg-transparent absolute"></div>
          <img
            id="standImage"
            src={ImageOne}
            alt="Cover Letter Template 1"
            className="w-1/2 object-cover absolute -rotate-12 top-10 rounded-md z-10"
          />
          <img
            id="standImage"
            src={ImageTwo}
            alt="Cover Letter Template 2"
            className="w-1/2 object-cover absolute top-[25%] rounded-md translate-x-1/2 z-20"
          />
          <img
            id="standImage"
            src={ImageThree}
            alt="Cover Letter Template 3"
            className="w-1/2 object-cover absolute bottom-0 rotate-[25deg] md:top-40 sm:top-56 top-28 rounded-md right-3 z-30"
          />
        </main>
      </main>
    </section>
  );
};

export default StandOutFromCrowd;
