import React from "react";
import CircleCheck from "../assets/circle-check.svg";
import ImageOne from "../assets/AbuDhabi_resume_template_preview.webp";
import ImageTwo from "../assets/SaoPaulo_resume_template_preview.webp";
import ImageThree from "../assets/Miami_resume_template_preview.webp";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import { trackResumeBuilderBottomDetailsClicked } from "../utils/analytics";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const CreatingAiWinning = () => {
  const { navigateTo } = useWebAppNavigation();

  useGSAP(() => {
    const mm = gsap.matchMedia();
    mm.add("(max-width: 1024px)", () => {
      ScrollTrigger.refresh();
      // console.log('small')
      // Create the timeline
      let createTl = gsap.timeline({
        scrollTrigger: {
          // markers: true,
          trigger: ["#CreatingAiH1", "#CreatingAip"],
          start: "top center+=30%",
          end: "top bottom",
          toggleActions: "play none none reverse",
        },
      });

      createTl.from(["#CreatingAiH1", "#CreatingAip"], {
        y: 100,
        opacity: 0,
        duration: 0.4,
        // stagger: 0.3,
      });

      createTl.from(["#creatAiBtn"], {
        y: 100,
        opacity: 0,
        duration: 0.8,
        // ease: "power2.out",
      });

      const timeL = gsap.timeline({
        scrollTrigger: {
          // markers: true,
          trigger: "#CreateAiimage",
          start: "top center+=20%",
          end: "top bottom",
          toggleActions: "play none none reverse",
          // scrub: true,
        },
      });
      // image animation
      timeL.from("#CreateAiimage", {
        x: -100, // Reduced movement
        opacity: 0,
        duration: 0.8,
        // stagger: 0.2, // Stagger the animations
        ease: "power3",
      });
    });

    mm.add("(min-width: 1024px)", () => {
      ScrollTrigger.refresh();
      // console.log("large");
      const largeItemTileLine = gsap.timeline({
        scrollTrigger: {
          // markers: true,
          trigger: ["#CreateAiimage"],
          start: "top center+=15%",
          end: "top bottom",
          toggleActions: "play none none reverse",
        },
      });

      largeItemTileLine
        // image animation
        .from("#CreateAiimage", {
          x: -100,
          opacity: 0,
          duration: 1,
          // stagger: 0.3,
          ease: "power2.out",
        })

        .from(
          ["#CreatingAiH1", "#CreatingAip"],
          {
            y: 100,
            opacity: 0,
            duration: 0.5,
            // stagger: 0.3,
          },
          "-=0.3"
        )

        .from(
          ["#creatAiBtn"],
          {
            scale: 0.1,
            opacity: 0,
            duration: 0.5,
            stagger: { each: 0.3 },
            ease: "Power3.out",
          },
          "-=0.1"
        );
    });
  }, []);

  const navigateToResumeBuilder = () => {
    trackResumeBuilderBottomDetailsClicked();
    navigateTo("resume_builder");
  };

  /**
   * <main className="bg-[#F0F0F1] md:mt-0 mt-8 rounded-2xl relative overflow-hidden md:w-[500px] md:h-[400px] w-full h-[80%] md:aspect-auto aspect-[5/4]">
          <div className="w-full h-full bg-black z-40 absolute bg-transparent"></div>
          <img
            id="CreateAiimage"
            src={ImageOne}
            alt="Cover Letter Template 1"
            className="w-1/2 object-cover rounded-md absolute md:right-[-8%] sm:right-[-12%] -right-16 sm:top-28 top-16 md:top-16 bottom-0 z-30"
          />
          <img
            id="CreateAiimage"
            src={ImageTwo}
            alt="Cover Letter Template 2"
            className="w-1/2 object-cover rounded-md absolute md:right-[46%] sm:top-40 sm:right-[45%] right-40 md:top-24 top-24 translate-x-1/2 z-20"
          />
          <img
            id="CreateAiimage"
            src={ImageThree}
            alt="Cover Letter Template 3"
            className="w-1/2 object-cover rounded-md absolute bottom-0 md:top-36 sm:top-60 top-36 md:right-[42%] sm:right-[42%] right-[36%] z-10"
          />
        </main>
   */

  return (
    <section className=" w-full">
      <main className="flex md:mb-24 mb-10 md:items-center flex-col-reverse md:flex-row md:space-x-12 mx-4 md:mx-[60px] md:justify-between justify-center items-center">
        <main className="bg-[#F0F0F1] md:mt-0 mt-8 rounded-2xl relative overflow-hidden md:w-[500px] md:h-[400px] w-full h-[80%] md:aspect-auto aspect-[5/4]">
          <div className="bg-black w-full h-full z-40 bg-transparent absolute"></div>
          <img
            id="CreateAiimage"
            src={ImageOne}
            alt="Cover Letter Template 1"
            className="w-1/2 object-cover md:top-36 left-10 absolute sm:top-72 top-36 rounded-md z-10"
          />
          <img
            id="CreateAiimage"
            src={ImageTwo}
            alt="Cover Letter Template 2"
            className="w-1/2 object-cover absolute md:top-24 sm:top-52 top-24 rounded-md md:translate-x-3/4 translate-x-2/3 z-20"
          />
          <img
            id="CreateAiimage"
            src={ImageThree}
            alt="Cover Letter Template 3"
            className="w-1/2 object-cover md:top-10 absolute sm:top-32 top-10 bottom-0 rounded-md md:right-[-16%] sm:-right-32 -right-16 z-30"
          />
        </main>

        <div className="md:w-[45%] w-full  md:mb-0 mb-24">
          <div className="overflow-hidden">
            <h1
              id="CreatingAiH1"
              className="text-2xl mb-4 text-[#1B1E21] font-[Satoshi-Black] leading-9"
            >
              Create Winning Resumes that Get Noticed
            </h1>
          </div>
          <div className="overflow-hidden">
            <div className=" overflow-hidden">
              <p
                id="CreatingAip"
                className="md:text-base text-[#515B6F] mb-4 text-sm leading-6 font-[Satoshi-Regular] tracking-wide"
              >
                Create professional resumes with ease, tailored to showcase your
                unique strengths and experiences, and impress employers.
              </p>
            </div>
            <div id="CreatingAip">
              {[
                "Quickly generate standout resumes with our AI-powered tool, streamlining your job application process.",
                "Receive tailored suggestions to showcase your skills and experience effectively, maximizing your chances of success.",
                "Easily navigate the platform to craft professional resumes, even with minimal experience in resume writing.",
                "Utilize professionally designed templates to ensure your resume makes a strong impression on employers.",
              ].map((text, index) => (
                <div
                  key={index}
                  className="flex items-start mb-4 overflow-hidden"
                >
                  <img
                    src={CircleCheck}
                    alt="Check"
                    className="mr-2 w-6 h-6 flex-shrink-0"
                  />
                  <p className="md:text-base text-[#515B6F] text-sm leading-4 font-[Satoshi-Regular] tracking-wide">
                    {text}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 md:flex grid md:grid-cols-2 md:space-x-4">
            <button
              onClick={navigateToResumeBuilder}
              id="creatAiBtn"
              className="bg-[#8B5CF6] font-[Satoshi-Regular] text-white px-4 py-2 rounded text-base"
            >
              Create my resume
            </button>
            {/* TODO: IMPLEMENT THIS LATER, POST MVP*/}
            {/*<button*/}
            {/*  id="creatAiBtn"*/}
            {/*  className=" border md:mt-0 mt-5 text-[#9357EA] border-[#DEDFE0] rounded font-[Satoshi-Regular] px-4 py-2 text-base"*/}
            {/*>*/}
            {/*  Explore templates*/}
            {/*</button>*/}
          </div>
        </div>
      </main>
    </section>
  );
};

export default CreatingAiWinning;
