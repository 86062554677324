
//handle emailm submit
export const handleEmailSubmit = async ({emailValue, setEmailValue, toast}, e) => {
  e.preventDefault();

  const emailData = {
    email: emailValue,
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EMAIL_BASE_URL}/v1/news-letter`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      }
    );
    const responseData = await response.json();
    if (response.ok) {
      toast.success(responseData.message);
      setEmailValue("");
    } else {
      toast.error(responseData.message || "An error occurred.");
    }
  } catch (error) {
    toast.error("Error submitting email: " + error.message);
  }
};