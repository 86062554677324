import React from "react";
import {ReactTyped} from 'react-typed'
const HeroTyped = () => {
  return (
    <ReactTyped
      backSpeed={90}
      onStringTyped={function noRefCheck() {}}
      onTypingPaused={function noRefCheck() {}}
      onTypingResumed={function noRefCheck() {}}
      strings={[
        "Resume",
        "Cover letter"
      ]}
      typeSpeed={90}
      loop={'true'}
      typedRef={function noRefCheck() {}}
    />
  );
};

export default HeroTyped;
