import React, { useEffect, useRef } from "react";
import interviewPreparationImg from "../assets/interviewPreparationImg.svg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

function InterviewPreparation() {
  const sectionRef = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(sectionRef.current, {
        opacity: 0,
        y: 50,
        duration: 0.5,
        ease: "power3.out",
        stagger: {
          each: 0.2,
        },
        scrollTrigger: {
          // markers: true,
          trigger: sectionRef.current,
          start: "top center",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });
      gsap.from("#sectionText", {
        opacity: 0,
        y: 50,
        duration: 0.5,
        ease: "power3.out",
        stagger: {
          each: 0.3,
        },
        scrollTrigger: {
          // markers: true,
          trigger: "#sectionText",
          start: "top center+=15%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });
      gsap.from("#interviewImg", {
        opacity: 0,
        y: 100,
        duration: 0.5,
        ease: "power3.out",
        stagger: {
          each: 0.3,
        },
        scrollTrigger: {
          // markers: true,
          trigger: "#interviewImg",
          start: "top center+=15%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <section
      ref={sectionRef}
      className="md:px-8 px-4 mb-14 rounded-2xl md:mx-20 mx-4 bg-[#3E2562] pt-8"
    >
      <main className="md:flex overflow-hidden md:justify-between justify-center items-center">
        <div className=" overflow-hidden">
          <p
            id="sectionText"
            className="w-24 font-[Satoshi-Bold] rounded-full h-6 text-[#9357EA] text-xs bg-[#E8E1F1] px-2 py-1"
          >
            Coming soon
          </p>
          <div className=" overflow-hidden">
            <h1
              id="sectionText"
              className="my-2 md:text-4xl text-xl font-[Satoshi-Black] text-[#FFFFFF] md:leading-10 leading-9"
            >
              Interview preparation
            </h1>
          </div>
          <div className=" overflow-hidden">
            <p
              id="sectionText"
              className="text-base text-[#F1F1F1] leading-6 font-[Satoshi-Regular] tracking-wider"
            >
              Ace your next interview with SwiftCareer’s comprehensive interview preparation toolkit. Designed to help you build confidence and sharpen your skills, this tool will guide you through mock interviews, key questions, and strategies to impress any employer. Prepare effectively and walk into every interview with the knowledge you need to succeed.
            </p>
          </div>
        </div>
        <img
          id="interviewImg"
          className=" md:mx-0 m-auto md:py-0 py-6"
          src={interviewPreparationImg}
          alt="interviewPreparationImg"
        />
      </main>
    </section>
  );
}

export default InterviewPreparation;
