import useWebAppNavigation from "../utils/UseWebAppNavigation";
import SubscriptionPlanItem from "./SubscriptionPlanItem";
import {XMarkIcon} from "@heroicons/react/24/solid";
import HeaderLogo from "../assets/originalLogo.svg";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function ProductPricePage() {

    const { navigateTo } = useWebAppNavigation()

    const navigate = useNavigate()

    const navigateToWebAppSubscriptionPage = () => {
        navigateTo("payment_page");
    }

    return (
        <div className={'flex flex-col h-full'}>
            {/*Top app bar */}
            <div
                className={
                    'flex flex-row justify-between items-center px-4 py-4 md:px-20 md:py-8'
                }>
                <div className='flex items-center space-x-2'>
                    <img className='w-[32px] bg-white h-[32px] object-cover' src={HeaderLogo}
                         alt="headerLogo-error"/>
                    <h3
                        className='text-[#35254B] text-[18px] font-[Satoshi-Black] leading-[24px] capitalize'>Swift<span
                        className='text-[#9357EA]'>Career</span></h3>
                </div>

                <XMarkIcon
                    className={'w-6 h-6 text-grey-scale-mid-emphasis-text'}
                    onClick={() => {
                        navigate(-1)
                    }}
                />
            </div>

            {/* body */}
            <div
                className={
                    'flex flex-col grow h-full px-4 md:px-20 mt-6 items-center gap-10 mb-20'
                }>
                <div className={'flex flex-col gap-2'}>
                    <div className="text-center text-gray-900 text-2xl font-black font-satoshi leading-loose">
                            Accelerate Your Career Growth with Our Premium
                            Plan
                        </div>

                        <div
                            className="text-center text-zinc-600 text-base font-normal font-satoshi leading-normal tracking-tight">
                            Unlock exclusive features and personalized
                            support to take your career to new heights.
                        </div>
                    </div>


                    <div className={'grid grid-cols-1 w-full md:w-fit'}>
                        <SubscriptionPlanItem
                            className={'w-full md:w-[400px]'}
                            onBuyNowClicked={navigateToWebAppSubscriptionPage}
                        />
                    </div>

                </div>

        </div>
    )
}