import React, { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { Link } from "react-router-dom";
import AiImage from "../assets/groupImages/imageThree.svg";
import arrowLeftIcons from "../assets/groupImages/arrow-narrow-left-Three.svg";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import {trackResumeBuilderClicked} from "../utils/analytics";
const AiResumeBuilder = () => {
  const sectionRef = useRef(null);
  const { navigateTo } = useWebAppNavigation()

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(sectionRef.current, {
        opacity: 0,
        y: 50,
        duration: 0.5,
        ease: "power3.out",
        stagger: {
          each: 0.2,
        },
        scrollTrigger: {
          // markers: true,
          trigger: sectionRef.current,
          start: "top center",
          end: "top bottom",
          toggleActions: "play none none reverse",
        },
      });

      gsap.from("#BuilderLeft", {
        x: -100,
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          // markers: true,
          trigger: "#BuilderLeft",
          start: "top center+=15%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });

      gsap.from("#builderImg", {
        x: 100,
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          // markers: true,
          trigger: "#builderImg",
          start: "top center+=5%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  const navigateToResumeBuilder = () => {
    trackResumeBuilderClicked()
    navigateTo('resume_builder')
  }


  return (
    <section
    id="AIResumeBuilder"
      ref={sectionRef}
      className="w-full rounded-2xl bg-[#EBF9F8] md:py-10 py-10 mt-6 md:px-14 px-4"
    >
      <main className="md:flex overflow-hidden w-full md:justify-between justify-center items-center">
        <div className="">
          <h1
            id="BuilderLeft"
            className="md:text-3xl md:mb-2 text-2xl text-[#1B1E21] font-[Satoshi-Black] md:leading-10 leading-8 "
          >
            AI-Powered Resume builder
          </h1>
          {/* <p
            id="BuilderLeft"
            className="p-1 md:hidden block sm:block bg-[#FBEED4] my-1 text-xs font-[Satoshi-Medium] leading-4 w-24 text-center rounded-full h-6 "
          >
            Coming soon
          </p> */}
          <p
            id="BuilderLeft"
            className="md:text-4 text-sm text-[#515B6F] font-[Satoshi-Regular] tracking-wider leading-6"
          >
            Create tailored resumes that highlight your strengths and optimize
            your chances of securing interviews effortlessly.
          </p>
          <button id="BuilderLeft" className="mt-4 space-x-2 flex items-center"
                  onClick={navigateToResumeBuilder}>
            <p className="text-4 text-[#0E5953] font-[Satoshi-Black] leading-5">
              Create my resume
            </p>
            <img className="" src={arrowLeftIcons} alt="arrowLeftIcons" />
          </button>
        </div>

        <img
          id="builderImg"
          className="md:w-1/2 sm:w-[80%] md:h-auto h-[300px] w-full mx-auto md:mt-0 mt-8"
          src={AiImage}
          alt="AiImage"
        />
      </main>
    </section>
  );
};

export default AiResumeBuilder;
