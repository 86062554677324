import React, { useState } from "react";
import Img from '../assets/Resume design (CV)nw.webp';
import ImgCover from '../assets/FrameCoverpng.webp';
import SingRepo from "./SingleResume";
import ScrollToTop from "./ScrollToTop";
import { motion } from "framer-motion";
const BestProfessional = () => {
    const [switchBtn, setSwitchBtn] = useState(true);

    const Images = [
        {
            id: 1,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
        {
            id: 2,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
        {
            id: 3,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
        {
            id: 4,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
        {
            id: 5,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
        {
            id: 6,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
        {
            id: 7,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
        {
            id: 8,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
        {
            id: 9,
            TemplateImage: `${Img}`,
            CoverImage: `${ImgCover}`,
            TemplateName: 'Template name'
        },
    ]

  return (
    <section className="md:px-[96px] overflow-hidden mt-[56px] px-[1rem] md:py-[80px] py-[52px]">
      <ScrollToTop />
      <motion.div initial={{y: 20, opacity: 0}} whileInView={{y: 0, opacity: 1}} transition={{ease: "easeOut", delay: 0.5}}>
      <div className="md:w-[789px] m-auto text-center">
        <h1 className="md:text-[40px] text-[28px] leading-[36px] text-[#25324B] font-[Satoshi-Black] md:leading-[52px]">
          Best Professional & Modern Resume & Cover Letter’s Templates
        </h1>
        <p className="md:text-[18px] mt-[8px] text-[14px] text-[#515B6F] font-[Satoshi-Regular] leading-[24px] tracking-[0.14px] md:leading-[28px]">
          Our templates were all designed by Certified Career Experts.
        </p>
        <div className=" flex justify-center items-center w-full">
        <div className="px-[1rem] flex md:w-[360px] w-[343px] rounded-[100px] space-x-2 m-auto mt-[24px] bg-[#FAFAFA] py-[8px]">
          <button onClick={() => setSwitchBtn(true)} className={switchBtn ? "text-[12px] ml-[-5px] m-auto md:w-[170px] shadow-md md:h-[40px] px-[1rem] py-[12px] bg-[#fff] rounded-[100px] font-[Satoshi-Black] leading-[1rem]" : "text-[12px] md:w-[170px] ml-[-5px] md:h-[40px] px-[1rem] py-[12px] bg-[#fff] rounded-[100px] font-[Satoshi-Black] leading-[1rem]"}>Resume templates</button>
          <button onClick={() => setSwitchBtn(false)} className={switchBtn ? "text-[12px] md:w-[170px] m-auto md:h-[40px] px-[1rem] py-[12px] bg-[#fff] rounded-[100px] font-[Satoshi-Black] leading-[1rem]" : "text-[12px] md:w-[170px] shadow-md md:h-[40px] px-[1rem] py-[12px] bg-[#fff] rounded-[100px] font-[Satoshi-Black] leading-[1rem]"}>Cover letter templates</button>
        </div>
        </div>
      </div>


      <div className=" bg-[#fff] gap-[24px] grid md:grid-cols-3 grid-cols-1">
        {Images.map((item) => <div key={item.id} className=" w-full">
            <SingRepo  item={item} templateName ={item.TemplateName} image={switchBtn ? item.TemplateImage : item.CoverImage} />
        </div>)}
      </div>

      {/* see More Button */}
      <div className="flex justify-center mt-[40px]">
        <button className="text-[1rem] border rounded-[4px] border-[#DEDFE0] px-[27px] py-[14px] leading-[20px] font-[Satoshi-Bold] text-[#9357EA]">See more templates</button>
      </div>
      </motion.div>
    </section>
  );
};

export default BestProfessional;
