import React from "react";
import AiResumeReview from "./AiResumeReview";
import JobTracker from "./JobTracker";
import AiResumeBuilder from "./AiResumeBuilder";
import AiCoverLetterBuilder from "./AiCoverLetterBuilder";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Features = () => {
  useGSAP(() => {
    gsap.from("#featureText", {
      y: 100,
      opacity: 0,
      duration: 1.5,
      stagger: 0.5,
      scrollTrigger: {
        // markers: true,
        trigger: "#featureText",
        start: "top center+=20%",
        end: "top bottom",
        scrub: 1,
      },
    });
  }, []);

  return (
    <section className="md:py-20 md:px-20 px-4 py-14">
      <main className="">
        <div className=" overflow-hidden">
          <h3
            id="featureText"
            className="animate-text text-3 font-[Satoshi-Black] text-[#9357EA] leading-4 tracking-wider text-center uppercase"
          >
            Features
          </h3>
        </div>

        <div className="overflow-hidden">
          <h1
              id="featureText"
              className=" md:text-[2.5rem] md:w-[800px] m-auto text-center w-full pb-1 text-[2.1rem] leading-[2.7rem] md:leading-[3.2rem] font-[Satoshi-Black]"
          >
            From Job Application to Offer
          </h1>
          <h1
              id="featureText"
              className=" md:text-[2.5rem] md:w-[800px] m-auto text-center w-full pb-1 text-[2.1rem] leading-[2.7rem] md:leading-[3.2rem] font-[Satoshi-Black]"
          >
            Explore Our Comprehensive Toolkit for Career Success
          </h1>
        </div>
        {/*<div className=" overflow-hidden">*/}
        {/*  <p*/}
        {/*    id="featureText"*/}
        {/*    className="animate-text md:text-base leading-6 font-[Satoshi-Regular] text-center tracking-wide mt-1"*/}
        {/*  >*/}
        {/*    Craft resumes & cover letters with AI precision to stand out in your*/}
        {/*    job search*/}
        {/*  </p>*/}
        {/*</div>*/}
      </main>

      <main>
        <AiResumeReview />
        <JobTracker />
        <AiResumeBuilder />
        <AiCoverLetterBuilder />
      </main>
    </section>
  );
};

export default Features;
