import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VodafoneImg from "../assets/vodafone-2017-logo.svg";
import IntelImg from "../assets/intel-3.svg";
import Tesla from "../assets/tesla-9 1.svg";
import Amda from "../assets/amd-logo-1.svg";
import TalkiImg from "../assets/talkit 1.svg";

const CompaniesHiredApp = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    };

    const tl = gsap.timeline();

    tl.fromTo(
      [sectionRef.current, "#companiesP", "#companiesImg"],
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.2
      }
    );

    ScrollTrigger.create({
      // markers: true,
      trigger: sectionRef.current,
      start: "top center+=30%",
      end: "bottom center",
      animation: tl,
         toggleActions: "play none play reverse"
    });

    return () => {
      ScrollTrigger.getAll().forEach(st => st.kill());
    };
  }, []);

  return (
    <section ref={sectionRef} className="md:mx-[5rem] mt-[2.688rem] mx-[1rem]">
      <main className="md:px-[5rem] px-[1rem] rounded-[1rem] bg-black md:py-[3rem] py-[3.5rem]">
       <div className=" overflow-hidden">
       <p
          id="companiesP"
          className="md:text-[1.125rem] mb-[2rem] text-[#515B6F] font-[Satoshi-Regular] text-[1rem] md:leading-[1.75rem] leading-[1.5rem] tracking-[0.01rem] "
        >
          Companies that have hired applicants who used our resume builder:
        </p>
       </div>
        <div className="grid justify-center gap-10 items-center md:grid-cols-5 sm:grid-cols-3 grid-cols-2">
          <img
            id="companiewImg"
            className="w-full md:scale-[1px] scale-[0.8]"
            src={VodafoneImg}
            alt="VodafoneImg"
          />
          <img
            id="companiewImg"
            className="w-full md:scale-[1px] scale-[0.8]"
            src={IntelImg}
            alt="IntelImg"
          />
          <img
            id="companiewImg"
            className="w-full md:scale-[1px] scale-[0.8]"
            src={Tesla}
            alt="Tesla"
          />
          <img
            id="companiewImg"
            className="w-full md:scale-[1px] scale-[0.8]"
            src={Amda}
            alt="Amda"
          />
          <img
            id="companiewImg"
            className="w-full md:scale-[1px] scale-[0.8]"
            src={TalkiImg}
            alt="TalkiImg"
          />
        </div>
      </main>
    </section>
  );
};

export default CompaniesHiredApp;
