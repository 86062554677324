import React, { useRef } from "react";
import HeaderLogo from "../assets/originalLogo.svg";
import { MdClose } from "react-icons/md";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import { trackLoginClicked, trackSignUpClicked } from "../utils/analytics";

const SideBar = ({ isOpen, onClose }) => {
  const loginButtonRef = useRef(null);
  const signUpButtonRef = useRef(null);
  const { navigateTo } = useWebAppNavigation();

  const navigateToLogin = () => {
    trackLoginClicked();
    navigateTo("login");
  };

  const navigateToSignUp = () => {
    trackSignUpClicked();
    navigateTo("signup");
  };
  return (
    <aside
      className={`fixed top-0 left-0 h-screen w-full md:hidden bg-[#ffffff] text-white flex flex-col pb-20 z-50 transform transition-transform duration-500 delay-700 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className=" absolute flex justify-between w-full items-center mx-4 top-6">
        <div className="flex mt-[2px] items-center space-x-2">
          <img
            className="w-[32px] bg-white object-cover"
            src={HeaderLogo}
            alt="headerLogo-error"
          />
          <h3 className="text-[#35254B] text-[18px] font-[Satoshi-Black] leading-[24px] capitalize">
            Swift<span className="text-[#9357EA]">Career</span>
          </h3>
        </div>

        <button
          onClick={onClose}
          className={
            isOpen === true
              ? "absolute border-black right-8 p-2 mt-2 text-white text-2xl"
              : "absolute border-black border right-8 p-2 mt-2 rounded-full text-white text-2xl"
          }
        >
          <MdClose color="black" />
        </button>
      </div>

      <div className=" mt-32">
        <ul className=" text-start mx-[16px]">
          {["Blog", "Resume Builder", "Cover letter Builder"].map(
            (text, index) => (
              <li
                className=" my-2 border-b font-[Satoshi-Regular] text-base text-[#515B6F] py-4 rounded-lg font-semibold transition duration-300"
                key={index}
              >
                {index === 0 ? (
                  <a
                    href="https://medium.com/@swiftcareer_"
                    target="_blank"
                    className={
                      index === 0
                        ? "text-4 cursor-pointer font-[Satoshi-Bold] leading-5 active:text-[#9357EA]"
                        : "text-4 active:text-[#9357EA] text-[#9357EA] cursor-pointer font-[Satoshi-Bold] leading-5"
                    }
                  >
                    {text}
                  </a>
                ) : index === 1 ? (
                  <a
                    onClick={onClose}
                    href="#AIResumeBuilder"
                    className={
                      index === 1
                        ? "text-4 cursor-pointer font-[Satoshi-Bold] leading-5"
                        : "text-4 text-[#9357EA] cursor-pointer font-[Satoshi-Bold] leading-5"
                    }
                  >
                    {text}
                  </a>
                ) : index === 2 ? (
                  <a
                    onClick={onClose}
                    href="#AICoverLetterBuilder"
                    className={
                      index === 2
                        ? "text-4 cursor-pointer font-[Satoshi-Bold] leading-5"
                        : "text-4 text-[#9357EA] cursor-pointer font-[Satoshi-Bold] leading-5"
                    }
                  >
                    {text}
                  </a>
                ) : (
                  <span className="text-4 text-[#515B6F] cursor-pointer font-[Satoshi-Bold] leading-5">
                    {text}
                  </span>
                )}
              </li>
            )
          )}
        </ul>
      </div>

      {/* navbars */}
      <nav className="flex h-full flex-col justify-end space-y-6 w-full px-4">
        {/* listnav */}

        <button
          ref={signUpButtonRef}
          onClick={navigateToSignUp}
          className=" text-white border border-[#9357EA] bg-[#9357EA] hover:bg-white hover:text-[#9357EA] py-2 px-6 rounded-lg text-lg font-semibold transition duration-300"
        >
          Sign Up
        </button>

        <button
          ref={loginButtonRef}
          onClick={navigateToLogin}
          className=" border border-[#9357EA] text-[#9357EA] hover:bg-[#9357EA] hover:text-white py-2 px-6 rounded-lg text-lg font-semibold transition duration-300"
        >
          Login
        </button>
      </nav>
    </aside>
  );
};

export default SideBar;
