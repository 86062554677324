import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AiImage from "../assets/groupImages/imageFour.svg";
import arrowLeftIcons from "../assets/groupImages/arrow-narrow-left-Four.svg";
import gsap from "gsap";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import {trackCoverLetterBuilderClicked} from "../utils/analytics";

const AiCoverLetterBuilder = () => {
  const sectionRef = useRef(null);
  const { navigateTo } = useWebAppNavigation()
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(sectionRef.current, {
        opacity: 0,
        y: 50,
        duration: 0.5,
        ease: "power3.out",
        stagger: {
          each: 0.2,
        },
        scrollTrigger: {
          // markers: true,
          trigger: sectionRef.current,
          start: "top center",
          end: "top bottom",
          toggleActions: "play none none reverse",
        },
      });

      gsap.from("#AiCoverLeft", {
        x: 100,
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          // markers: true,
          trigger: "#AiCoverLeft",
          start: "top center+=16%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });

      gsap.from("#AiCoverImg", {
        x: -100,
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          // markers: true,
          trigger: "#AiCoverImg",
          start: "top center+=5%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  const navigateToCoverLetterBuilder = () => {
    trackCoverLetterBuilderClicked()
    navigateTo('cover_letter_builder')
  }


  return (
    <section
    id="AICoverLetterBuilder"
      ref={sectionRef}
      className="w-full mb-5 rounded-2xl bg-[#F6F5F1] md:py-10 py-10 mt-6 md:px-14 px-4"
    >
      <main className="flex overflow-hidden flex-col-reverse md:flex-row md:space-x-12 w-full md:justify-between justify-center items-center">
        <img
          id="AiCoverImg"
          className="md:w-1/2 sm:w-[80%] md:h-auto h-[300px] w-full mx-auto md:mt-0 mt-8"
          src={AiImage}
          alt="AiImage"
        />

        <div className="">
          <h1
            id="AiCoverLeft"
            className="md:text-3xl md:mb-2 text-2xl text-[#1B1E21] font-[Satoshi-Black] md:leading-10 leading-8 "
          >
            AI-Powered Cover letter builder
          </h1>
          {/* <p
            id="AiCoverLeft"
            className="p-1 md:hidden block sm:block bg-[#FBEED4] my-1 text-xs font-[Satoshi-Medium] leading-4 w-24 text-center rounded-full h-6 "
          >
            Coming soon
          </p> */}
          <p
            id="AiCoverLeft"
            className="md:text-4 text-sm text-[#515B6F] font-[Satoshi-Regular] tracking-wider leading-6"
          >
            Create job-winning cover letters tailored to your unique skills and the position you're applying for. SwiftCareer’s personalised cover letter generator crafts compelling, professional letters that make a lasting impression, helping you stand out and land more interviews. Save time and boost your chances of success with perfectly customised cover letters every time!
          </p>
          <button id="AiCoverLeft" className="mt-4 space-x-2 flex items-center"
                  onClick={navigateToCoverLetterBuilder}>
            <p className="text-base text-[#5F4D05] font-[Satoshi-Black] leading-5">
              Create my cover letter
            </p>
            <img className="" src={arrowLeftIcons} alt="arrowLeftIcons" />
          </button>
        </div>
      </main>
    </section>
  );
};

export default AiCoverLetterBuilder;
