import React, { useEffect, useRef } from "react";
import ApproveImg from "../assets/approve-resume 1.svg";
import ResumeOne from "../assets/resume 1.svg";
import PaperAirplain from "../assets/paper-airplane-1.svg";
import newIcon from "../assets/templateOneAdd.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TapInAiPower = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }

    const gridItems = gsap.utils.toArray("#gridContainer");

    gridItems.forEach((item) => {
      gsap.fromTo(
        item,
        { y: 80, opacity: 0, duration: 0.4 },
        {
          y: 0,
          opacity: 1,
          duration: 0.4,
          stagger: 0.5,
          ease: "power2.out",
          scrollTrigger: {
            // markers: true,
            trigger: item,
            start: "top center+=20%",
            end: "bottom bottom",
            toggleActions: "play none none reverse",
            once: false,
          },
        }
      );
    });

    // const mediaQueryMobile = window.matchMedia("(max-width: 640px)");
    // if (mediaQueryMobile.matches) {
    //   gridItems.forEach((item) => {
    //     gsap.fromTo(
    //       item,
    //       { y: 80, opacity: 0, duration: 0.4 },
    //       {
    //         y: 0,
    //         opacity: 1,
    //         duration: 0.4,
    //         stagger: 0.5,
    //         ease: "power2.out",
    //         scrollTrigger: {
    //           markers: true,
    //           trigger: item,
    //           start: "top center",
    //           end: "bottom center",
    //           toggleActions: "play none none reverse",
    //           once: false,
    //         },
    //       }
    //     );
    //   });
    // }

    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: ["#EquipH1", "#EquipP"],
        start: "top center+=10%",
        end: "bottom bottom",
        toggleActions: "play none none reverse",
        once: false,
      },
    });

    tl.fromTo(
      ["#EquipH1", "#EquipP"],
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power2.out",
      }
    );

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []); // This ensures the animations are scoped to the section

  return (
    <section className="md:px-[80px] md:py-[96px] px-[1rem] py-[56px]">
      <main>
        <div className="md:w-[773px] px-4 m-auto md:text-center">
          <div className=" overflow-hidden">
            <h1
              id="EquipH1"
              className="md:text-[2.5rem] pb-1 text-[2.1rem] leading-[2.7rem] md:leading-[3.2rem] font-[Satoshi-Black] "
            >
              Equipped With All The Tools You Need To Land Your Dream Job
            </h1>
          </div>
          <div className=" overflow-hidden">
            <p
              id="EquipP"
              className=" md:text-[16px] text-[14px] font-[Satoshi-Regular] text-[#515B6F] leading-[24px] tracking-[0.14px]"
            >
              Craft resumes & cover letters with AI precision to stand out from the crowd
            </p>
          </div>
        </div>

        <main className="md:mt-14 mt-10">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            {/* Review & Re-write section */}
            <div
              id="gridContainer"
              className="bg-[#F1EBF9] overflow-hidden h-[396px] sm:h-[300px] md:h-[350px] rounded-[1rem] flex flex-col justify-between"
            >
              <div className="mb-26 md:px-8 md:pt-8 px-4 pt-6">
                <h2 className="text-[1.5rem] font-[Satoshi-Black] mb-2">
                  Level up Your Resume in Minutes with AI Analysis
                </h2>
                <p className="text-[#515B6F] font-[Satoshi-Regular] leading-6 md:text-base text-sm">
                  Upload your resume and get instant, data-backed suggestions
                  for improvements.
                </p>
              </div>
              <img
                id="gridContainerImage"
                src={PaperAirplain}
                alt="Resume Review Icon"
                className="md:w-[180px] sm:w-[150px] w-[120px] md:mt-0 self-end "
              />
            </div>

            {/* Craft a professional cover letter section */}
            <div
              id="gridContainer"
              className="bg-[#FFF7E7] overflow-hidden rounded-[1rem] md:h-auto h-[396px] row-span-2 flex flex-col justify-between"
            >
              <div className="md:px-8 md:pt-8 px-4 pt-6">
                <h2 className="text-[1.5rem] font-[Satoshi-Black] mb-2">
                  Craft a professional cover letter that tells a story
                </h2>
                <p className="text-[#515B6F] font-[Satoshi-Regular] leading-6 md:text-base text-sm">
                  Make a lasting impression with a compelling cover letter that
                  showcases your unique story and qualifications.
                </p>
              </div>
              <img
                id="gridContainerImage"
                src={ResumeOne}
                alt="Cover Letter Icon"
                className="md:w-[270px] sm:w-[150px] w-[120px] md:mt-24 self-end"
              />
            </div>

            {/* Craft a resume section */}
            <div
              id="gridContainer"
              className="bg-[#EBF9F8] overflow-hidden md:h-auto h-[396px] rounded-[1rem] row-span-2 flex flex-col justify-between"
            >
              <div className="md:px-8 md:pt-8 px-4 pt-6">
                <h2 className="text-[1.5rem] font-[Satoshi-Black] mb-2">
                  Craft a resume that catches recruiters' attention
                </h2>
                <p className="text-[#515B6F] w-full font-[Satoshi-Regular] leading-6 md:text-base text-sm">
                  Our AI-resume builder stands you out and makes a lasting
                  impression on recruiters. We help your resume become truly
                  competitive & hard to ignore in the hiring process.
                </p>
              </div>
              <img
                id="gridContainerImage"
                src={ApproveImg}
                alt="Resume Icon"
                className="md:w-[270px] sm:w-[150px] w-[120px] self-end md:mt-24 "
              />
            </div>

            {/* 20+ Best Professional Resume & Cover Letter's Templates section */}
            <div
              id="gridContainer"
              className="bg-[#F6F5F1] overflow-hidden sm:h-[300px] md:h-[350px] h-[396px] rounded-[1rem] flex flex-col justify-between"
            >
              <div className="md:px-8 md:pt-8 px-4 pt-6">
                <h2 className="text-[1.5rem] font-[Satoshi-Black] mb-2">
                  20+ Best Professional Resume & Cover Letter Templates
                </h2>
                <p className="text-[#515B6F] w-full font-[Satoshi-Regular] leading-6 md:text-base text-sm">
                  Beat the ATS, Impress the Recruiters: Expert-Crafted Resume and Cover Letter Templates
                </p>
              </div>
              <img
                id="gridContainerImage"
                src={newIcon}
                alt="Templates Icon"
                className="md:w-[180px] sm:w-[150px] w-[120px] self-end md:mt-4"
              />
            </div>
          </div>
        </main>
      </main>
    </section>
  );
};

export default TapInAiPower;
