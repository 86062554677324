import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AiImage from "../assets/groupImages/imageTwo.svg";
import arrowLeftIcons from "../assets/groupImages/arrow-narrow-left-Two.svg";
import gsap from "gsap";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import {trackJobTrackerClicked} from "../utils/analytics";

const JobTracker = () => {
  const sectionRef = useRef(null);
  const { navigateTo } = useWebAppNavigation()

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(sectionRef.current, {
        opacity: 0,
        y: 50,
        duration: 0.5,
        ease: "power3.out",
        stagger: {
          each: 0.2,
        },
        scrollTrigger: {
          // markers: true,
          trigger: sectionRef.current,
          start: "top center",
          end: "top bottom",
          toggleActions: "play none none reverse",
        },
      });

      gsap.from("#JobLeft", {
        x: 100,
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          // markers: true,
          trigger: "#JobLeft",
          start: "top center+=15%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });

      gsap.from("#Jobimg", {
        x: -100,
        opacity: 0,
        duration: 0.5,
        ease: "power3.out",
        stagger: 0.2,
        scrollTrigger: {
          // markers: true,
          trigger: "#Jobimg",
          start: "top center+=5%",
          end: "bottom center",
          toggleActions: "play none none reverse",
        },
      });
    }, sectionRef); // Scope to sectionRef

    return () => ctx.revert(); // Cleanup
  }, []);

  const navigateToJobTrackerBoard = () => {
    trackJobTrackerClicked()
    navigateTo('job_tracker')
  }


  return (
    <section
      ref={sectionRef}
      id="JobTracker"
      className="w-full rounded-2xl bg-[#F6F5FD] md:py-10 py-10 mt-6 md:px-14 px-4"
    >
      <main className="flex overflow-hidden flex-col-reverse md:flex-row md:space-x-12 w-full md:justify-between justify-center items-center">
        <img
          id="Jobimg"
          className="md:w-[40%] sm:w-[65%] w-[80%] md:h-auto h-[300px] mx-auto md:mt-0 mt-8"
          src={AiImage}
          alt="AiImage"
        />

        <div className="md:w-[40%]">
          <h1
            id="JobLeft"
            className="md:text-3xl md:mb-2 text-2xl text-[#1B1E21] font-[Satoshi-Black] md:leading-10 leading-8 "
          >
            Job Application Tracker
          </h1>
          {/* <p
            id="JobLeft"
            className="p-1 md:hidden block sm:block bg-[#FBEED4] my-1 text-xs font-[Satoshi-Medium] leading-4 w-24 text-center rounded-full h-6 "
          >
            Coming soon
          </p> */}
          <p
            id="JobLeft"
            className="md:text-4 text-sm text-[#515B6F] font-[Satoshi-Regular] tracking-wider leading-6"
          >
            Say goodbye to the chaos of managing multiple job applications. With SwiftCareer’s Job Tracker, you can easily organize and monitor every step of your job search—from applications to interviews—ensuring you never miss a deadline or follow-up. Stay in control and boost your productivity with a clear overview of your progress, all in one place.
          </p>
          <button id="JobLeft" className="mt-4 space-x-2 flex items-center" onClick={navigateToJobTrackerBoard}>
            <p className="text-base text-[#9357EA] font-[Satoshi-Black] leading-5">
              Try now for FREE
            </p>
            <img className="" src={arrowLeftIcons} alt="arrowLeftIcons" />
          </button>
        </div>
      </main>
    </section>
  );
};

export default JobTracker;
