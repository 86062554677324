import React, { useRef, useState } from "react";
import ReviewedImg from "../assets/ReviewedImg.svg";
import ReviewedImgOne from "../assets/EllipseImg1.svg";
import ReviewedImgTwo from "../assets/Ellipse 2.svg";
import ReviewedImgThree from "../assets/Ellipse 3.svg";
import ReviewedImgFour from "../assets/Ellipse 4.svg";
import ReviewedImgFive from "../assets/Ellipse 5.svg";
import ReviewedImgSix from "../assets/Ellipse 6.svg";
import Marquee from "react-fast-marquee";
import ReviewText from "./ReviewText";
import quoteSvg from "../assets/VectorQuote.svg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReviewedByComm = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const cursor = useRef(null);
  const marqueeRef = useRef(null);
  let positionX = 0;
  let positionY = 0;
  let mouseX = 0;
  let mouseY = 0;

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.to({}, 0.016, {
      repeat: -1,
      onRepeat: () => {
        positionX += (mouseX - positionX) / 10;
        positionY += (mouseY - positionY) / 10;

        gsap.set(cursor.current, {
          css: {
            left: positionX,
            top: positionY,
          }
        });
      }
    });

   
      gsap.from("#ReviewText", {
        y: 100,
        opacity: 0,
        duration: 1.5,
        stagger: 0.5,
        scrollTrigger: {
          // markers: true,
          trigger: "#ReviewText",
          start: "top center+=20%",
          end: "top bottom",
          scrub: 1,
        },
      });

    // Add rotation animation
    gsap.to(cursor.current.querySelector('svg'), {
      rotation: 360,
      duration: 10,
      repeat: -1,
      ease: 'linear'
    });

    const handleMouseMove = (e) => {
      if (marqueeRef.current) {
        const rect = marqueeRef.current.getBoundingClientRect();
        mouseX = e.clientX - rect.left;
        mouseY = e.clientY - rect.top;
      }
    };

    marqueeRef.current.addEventListener("mousemove", handleMouseMove);

    return () => {
      marqueeRef.current.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const TextAnimationOne = [
    {
      id: 1,
      review: `Crafted a winning cover letter
             with Swiftcareer's intuitive tool, and it made all the difference in landing 
            interviews! Highly recommended. And it's very easy to use. I recommend it`,
      name: " Jennifer L",
      occupation: "Marketing coordinator",
      image: `${ReviewedImg}`,
      vectorSvg: `${quoteSvg}`,
    },
    {
      id: 2,
      review: `Swiftcareer transformed my job search! With their AI-powered resume builder, 
            I crafted a standout resume that landed me multiple interviews. Highly recommend!`,
      name: "  Emily  S",
      occupation: "Marketing Manager",
      image: `${ReviewedImgOne}`,
      vectorSvg: `${quoteSvg}`,
    },
    {
      id: 3,
      review: `I was skeptical at first, but Swiftcareer's 
            cover letter maker exceeded my expectations. As a software 
            engineer, my cover letter was professional and engaging, 
            helping me secure my dream job. `,
      name: " Alex T",
      occupation: "Software Engineer",
      image: `${ReviewedImgTwo}`,
      vectorSvg: `${quoteSvg}`,
    },
    {
      id: 4,
      review: `Swiftcareer transformed my job search! With their AI-powered resume builder, 
            I crafted a standout resume that landed me multiple interviews. Highly recommend!`,
      name: "  Emily  S",
      occupation: "Marketing Manager",
      image: `${ReviewedImgThree}`,
      vectorSvg: `${quoteSvg}`,
    },
    {
      id: 5,
      review: `Throughout my job search, Swiftcareer's job 
            application tracker kept me organized and motivated. 
            It's an invaluable resource for anyone navigating the job market.`,
      name: " Mike Dave",
      occupation: "Product Assistant",
      image: `${ReviewedImgFour}`,
      vectorSvg: `${quoteSvg}`,
    },
    {
      id: 6,
      review: `The job application tracker board on Swiftcareer 
            kept me organized and on top of my applications. 
            As a project manager, it's a game-changer for anyone 
            serious about their job search.`,
      name: " Sarah Lopez",
      occupation: "Product Manager",
      image: `${ReviewedImgFive}`,
      vectorSvg: `${quoteSvg}`,
    },
    {
      id: 7,
      review: `Swiftcareer's automatic job match feature was a life saver. 
            As a sales representative, it matched me with relevant job openings based
             on my skills & preferences, making the job search process effortless`,
      name: " Mike Davidson",
      occupation: "Sales Representative",
      image: `${ReviewedImgSix}`,
      vectorSvg: `${quoteSvg}`,
    },
    {
      id: 8,
      review: `I've tried other job search platforms, but Swiftcareer 
            stands out. As a graphic designer, 
            the job openings aggregator provided a 
            comprehensive list of opportunities`,
      name: " jessica Williams",
      occupation: "Graphic designer",
      image: `${ReviewedImgOne}`,
      vectorSvg: `${quoteSvg}`,
    },
    {
      id: 9,
      review: `Thanks to Swiftcareer's job openings aggregator, 
            I quickly found relevant opportunities that matched my skills 
            and preferences. It's truly a game-changer for job seekers`,
      name: "  Sarah K",
      occupation: "HR Specialist",
      image: `${ReviewedImgThree}`,
      vectorSvg: `${quoteSvg}`,
    },
  ];

  const CustomDot = ({ onClick, active }) => {
  
    return (
      <div
        style={{
          width:  '5px',
          height: '5px',
          backgroundColor: active ? 'blue' : '#F0F0F1',
          borderRadius: '100px',
          margin: '0 0',
          cursor: 'pointer',
          transition: 'all 0.2s ease'
        }}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => setActiveSlide(next),
    customPaging: (i) => <CustomDot active={i === activeSlide} />,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', bottom: '-30px' }}>
        <ul style={{ margin: '4', padding: '0', display: 'flex', justifyContent: 'center' }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <section className="md:px-[80px] px-[1rem] md:py-[98px] py-[56px]">
      <main>
        <div className="md:w-[773px] m-auto md:text-center text-start">
          <div className=" overflow-hidden">
            <h1
              id="ReviewText"
              className="md:text-[40px] text-[34px] font-[Satoshi-Black] md:leading-[52px] leading-[44px] text-[#1B1E21]"
            >
              Reviewed by the community, Trusted by professionals
            </h1>
          </div>
          <div className=" overflow-hidden">
            <p
              id="ReviewText"
              className="md:text-[1rem] mb-[20px] text-[14px] leading-[24px] md:tracking-[0.16px] tracking-[0.14px] font-[Satoshi-Regular] text-[#515B6F]"
            >
              Endorsed by our community and relied upon by seasoned
              professionals
            </p>
          </div>
        </div>

        <div 
          ref={marqueeRef}
          className="relative md:block hidden overflow-hidden"
        >
          <div
            ref={cursor}
            className="absolute pointer-events-none z-10"
            style={{
              transform: 'translate(-50%, -50%)',
            }}
          >
            <svg width="80" height="80" viewBox="0 0 80 80">
              <circle cx="40" cy="40" r="38" fill="#9357EA" />
              <path id="curvedPath" 
                d="M40,40 m-25,0 a25,25 0 1,1 50,0 a25,25 0 1,1 -50,0" 
                fill="none" 
              />
              <text fill="white" fontSize="11.5">
                <textPath xlinkHref="#curvedPath" startOffset="0%">
                  Read Reviews • Read Reviews • Read Reviews •
                </textPath>
              </text>
            </svg>
          </div>
          {/* Animation Left */}
          <Marquee gradient={true} gradientWidth={50}>
            <ReviewText
              review={TextAnimationOne[0].review}
              quoteSvg={TextAnimationOne[0].vectorSvg}
              name={TextAnimationOne[0].name}
              image={TextAnimationOne[0].image}
              occupation={TextAnimationOne[0].occupation}
            />
            <ReviewText
              review={TextAnimationOne[1].review}
              quoteSvg={TextAnimationOne[1].vectorSvg}
              name={TextAnimationOne[1].name}
              image={TextAnimationOne[1].image}
              occupation={TextAnimationOne[1].occupation}
            />
            <ReviewText
              review={TextAnimationOne[2].review}
              quoteSvg={TextAnimationOne[2].vectorSvg}
              name={TextAnimationOne[2].name}
              image={TextAnimationOne[2].image}
              occupation={TextAnimationOne[2].occupation}
            />
            <ReviewText
              review={TextAnimationOne[3].review}
              quoteSvg={TextAnimationOne[3].vectorSvg}
              name={TextAnimationOne[3].name}
              image={TextAnimationOne[3].image}
              occupation={TextAnimationOne[3].occupation}
            />
            <ReviewText
              review={TextAnimationOne[4].review}
              quoteSvg={TextAnimationOne[4].vectorSvg}
              name={TextAnimationOne[4].name}
              image={TextAnimationOne[4].image}
              occupation={TextAnimationOne[4].occupation}
            />
          </Marquee>
          <br />
          {/* Animation Right */}
          <Marquee direction="right" gradient={true} gradientWidth={50} delay={4}>
            <ReviewText
              review={TextAnimationOne[5].review}
              quoteSvg={TextAnimationOne[5].vectorSvg}
              name={TextAnimationOne[5].name}
              image={TextAnimationOne[5].image}
              occupation={TextAnimationOne[5].occupation}
            />
            <ReviewText
              review={TextAnimationOne[6].review}
              quoteSvg={TextAnimationOne[6].vectorSvg}
              name={TextAnimationOne[6].name}
              image={TextAnimationOne[6].image}
              occupation={TextAnimationOne[6].occupation}
            />
            <ReviewText
              review={TextAnimationOne[7].review}
              quoteSvg={TextAnimationOne[7].vectorSvg}
              name={TextAnimationOne[7].name}
              image={TextAnimationOne[7].image}
              occupation={TextAnimationOne[7].occupation}
            />
            <ReviewText
              review={TextAnimationOne[8].review}
              quoteSvg={TextAnimationOne[8].vectorSvg}
              name={TextAnimationOne[8].name}
              image={TextAnimationOne[8].image}
              occupation={TextAnimationOne[8].occupation}
            />
          </Marquee>
        </div>

        <div className="md:hidden block w-full py-8 px-4">
          <Slider {...settings}>
            {TextAnimationOne.map((item, index) => (
              <div key={index}>
                <div className="rounded-[16px] py-8 bg-[#FAFAFA] px-4">
                  <img src={item.vectorSvg} alt={item.vectorSvg} />
                  <p className="text-base mt-6 mb-4 font-[Satoshi-Black] leading-5">{item.review}</p>
                  <div className="flex items-center gap-3">
                    <img src={item.image} alt={item.name} />
                    <div className="block gap-2">
                      <p className="text-xs font-[Satoshi-Bold] leading-4">{item.name}</p>
                      <p className="text-xs font-[Satoshi-Regular] leading-4">{item.occupation}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </main>
    </section>
  );
};

export default ReviewedByComm;
